@import "../../styles/variable";
@import "../../styles/breakpoint";

.installment {
  width: 100%;
  margin-top: 30px;
  border: 1px solid $c-border;
  border-radius: 15px;
  overflow: hidden;
  position: relative;
  z-index: 2;
  display: none;

  .head {
    text-align: center;
    background: $c-g1;

    img {
      height: 80px;
    }
  }

  .row {
    padding: 12px 15px;
    display: grid;
    grid-template-columns: 2fr 2fr;
    align-items: center;
    border-bottom: 1px solid $c-border;
    font-size: 0.9rem;

    .instalmentNumber {
      display: flex;
      gap: 5px;
      align-items: center;

      label {
        display: flex;
        align-items: center;
        gap: 5px;
        cursor: pointer;

        .bonus {
          font-size: 0.7rem;
          font-weight: 700;
          background-color: $c1;
          width: 20px;
          height: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: $c-g1;
          border-radius: 10px;
        }
      }
    }
    .payment {
      text-align: right;
    }
    .totalPayment {
      text-align: right;
      font-weight: 700;
      color: $c1;
    }

    &:last-child {
      border: none;
    }

    &.title {
      font-weight: 600;
      text-align: center;
    }
  }

  &.active {
    display: block;
  }

  .noInstallement {
    text-align: center;
    display: block;
    padding: 10px;
    border-top: 1px solid $c-border;
  }
}
