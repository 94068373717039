@import "../../styles/variable";

.mainSlider {
  border-radius: 28px;
  overflow: hidden;
  margin-bottom: 40px;
}
.card {
  padding: 15px;
  border: 1px solid $c-border;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  text-align: center;
  font-weight: 600;

  &.bankCard {
    padding: 30px;
    align-items: center;

    img {
      height: 80px;
      max-width: 240px;
    }

    h2 {
      font-size: 18px;
    }
    p {
      font-size: 16px;
      
      strong {
        font-weight: 700;
      }
    }

    button, a {
      width: 100%;
    }
  }
}
.banks {
  max-width: 650px;
  overflow: hidden;

  .title {
    font-size: 1.1rem;
    font-weight: 600;
    margin-bottom: 50px;
  }

  .bank {
    display: grid;
    grid-template-columns: 170px auto;
    margin-bottom: 15px;
    padding-bottom: 15px;

    .logo {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }

    &:not(:last-child) {
      border-bottom: 1px solid $c-border;
    }

    .info {
      h2 {
        font-size: 1.1rem;
        font-weight: 600;
        margin-bottom: 10px;
      }
      ul {
        li {
          display: grid;
          grid-template-columns: 1fr 2fr;
          padding: 2px 0;

          span {
            &:first-child {
              font-weight: 600;
            }
            &:last-child {
              &::before {
                content: ": ";
              }
            }
          }
        }
      }
    }
  }
}
