@import "../../styles/variable";

.menuWrapper {
        height: 100vh;
        position: fixed;
        width: 70px;
        background: $c-g1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        border-radius: 0 30px 30px 0;
        z-index: 99;

    .logo {
        position: absolute;
        top: 80px;
        img {
            height: 20px;
            z-index: 2;
            transform: rotate(-90deg);
            filter: grayscale(1) opacity(.2);
            
        }
    }
    
    .menu {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0;
        width: 100%;
    
        ul {
            width: 100%;
            li {
                a {
                    display: flex;
                    width: 70px;
                    aspect-ratio: 1;
                    align-items: center;
                    justify-content: center;
                    border-bottom: 1px solid #ebebeb;
                    color: #7d7d7d;
                    position: relative;

                    svg {
                        filter: grayscale(1) opacity(.3);
                    }
        
                    &.active {
                        color: $c1;

                        svg {
                            filter: grayscale(0) opacity(1);
                        }
                    }
                    
                    span {
                        position: absolute;
                        top: 0;
                        left: 70px;
                        width: 0;
                        height: 70px;
                        display: flex;
                        align-items: center;
                        background: $c-g1;
                        border-bottom: 1px solid #ebebeb;
                        overflow: hidden;
                        opacity: 0;
                        transform: translateX(-5px);
                        transition: width .4s ease, opacity .7s ease, transform .7s ease;
                        text-wrap: nowrap;
                        text-transform: uppercase;
                        font-weight: 600;
                        font-size: 13px;
                    }
    
                    &:hover {
                        span {
                            width: 100px;
                            opacity: 1;
                            transform: translateX(0);
                        }
                        svg {
                            filter: grayscale(0) opacity(.8);
                        }
                    }
                }
        
                &:last-child {
                    a {
                        border:none;
                    }
                }
    
            }
        }
    }
}