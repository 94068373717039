p {
    line-height: 1.3;
    font-weight: 400;
    margin-bottom: 15px;
}

strong {
    font-weight: 600;
}

button {
    cursor: pointer;
    background: transparent;
    border: none;
}

.btn {
    line-height: 40px;
    background: $c1;
    color: #fff;
    font-weight: 600;
    border-radius: 7px;
    transition: all .3s;

    &.btnBlock {
        width: 100%;
    }

    &.btnSm {
        font-size: .6rem;
        line-height: 28px;
        background: $c2;
    }

    &:hover {
        background: $c2;
    }
}

h1 {
    color: $c2;
}

.textBold {
    font-weight: 700;
}