@use "../../styles/form";
@import "../../styles/variable";
@import "../../styles/breakpoint";

.loginWrapper {
  position: relative;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 25px;
  background: url("../../assets/img/login-bg.svg");
  background-size: cover;

  .logo {
    width: 60%;
    transform: translate(20px, 30px);
    img {
      width: 150px;
      filter: brightness(0) invert(1);
    }
  }

  h1 {
    font-weight: 700;
    font-size: 32px;
  }

  .login {
    background: #fff;
    border-radius: 15px;
    overflow: hidden;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    position: relative;
    width: max(850px, 1200px);
    height: 600px;
    aspect-ratio: 2/1;
    box-shadow: 0 0 45px rgba($c2, 0.7);

    @include tablet {
      grid-template-columns: 1fr;
      aspect-ratio: 2/3;
      width: 65%;
      max-height: 80%;
    }

    @include mobile {
      width: 90%;
    }

    h2 {
      font-size: 22px;
      font-weight: 800;
      margin-bottom: 12px;
      color: $c2;
    }
    p {
      line-height: 1.4;
      margin-bottom: 40px;
    }

    .loginForm {
      padding: 30px 25px;
    }
    .forgotForm {
      padding: 30px 25px;
      transform: translateX(30px);
      opacity: 0;

      @include tablet {
        transform: translate(0, 30px);
      }
    }
    .loginSlider {
      position: absolute;
      z-index: 2;
      height: 100%;
      width: 50%;
      top: 0;
      right: 0;
      border-radius: 15px;

      @include tablet {
        height: 50%;
        width: 100%;
        top: 50%;
        left: 0;
      }
    }

    .forgotText {
      font-size: 14px;
      margin-top: 15px;

      .link {
        color: $c1;
        font-weight: 500;
        cursor: pointer;
        transition: all 0.4s ease;
        padding: 0 5px;
        &:hover {
          color: $c2;
        }
      }
    }
  }
}
