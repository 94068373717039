@import "../../styles/variable";
@import "../../styles/breakpoint";

.payPage {
  display: grid;
  grid-template-columns: 370px auto;
  gap: 40px;

  @include tablet {
    grid-template-columns: 1fr;
  }
}

.card-wrapper * {
  pointer-events: none;
}

.card-wrapper {
  width: 370px;
  height: 240px;
  perspective: 1000px;

  .card {
    width: 100%;
    height: 100%;
    border-radius: 18px;
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    position: relative;
    overflow: hidden;
    background: linear-gradient(45deg, #060707, #404040);
    transition: all 0.5s ease;

    .highlight {
      position: absolute;
      height: 400px;
      width: 400px;
      border-radius: 50%;
      background: rgba(255, 255, 255, 0.1);
      filter: brightness(70%) blur(10px);
      top: -13%;
      left: -20%;
    }

    .masterIcon {
      position: absolute;
      left: 30px;
      top: 30px;
      display: flex;
      span {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        display: block;

        &:first-child {
          background: #eb221b;
        }
        &:last-child {
          background-color: #f8a329;
          opacity: 0.8;
          mix-blend-mode: lighten;
          transform: translateX(-15px);
        }
      }
    }

    .chip {
      position: absolute;
      top: 30px;
      img {
        width: 50px;
      }
    }

    .number {
      font-size: 1.4rem;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      span {
        height: 2.6rem;
        width: 13px;
        text-align: center;
        &.slide-fade {
          transform: translateY(-10px);
          opacity: 0;
          transition: all 0.2s ease;
        }
        &.slide-show {
          transform: translateY(10px);
          opacity: 0;
          transition: all 0.2s ease;
          animation: slideShow 0.2s forwards;
        }

        &:nth-child(4n) {
          margin-right: 10px;
        }
      }
    }

    .user {
      display: flex;
      width: 90%;
      align-items: center;
      justify-content: space-between;
      margin: 0 auto;
      .name {
        font-weight: 300;
        text-align: left;
        small {
          display: block;
          font-size: 0.8rem;
          margin-bottom: 2px;
        }
        span {
          display: block;
          font-weight: 500;
          font-size: 1.1rem;
        }
      }
      .date {
        small {
          display: block;
          font-size: 0.8rem;
          margin-bottom: 2px;
        }
        span {
          display: block;
          font-weight: 500;
          font-size: 1.1rem;
        }
      }
    }

    .bant,
    .ccv {
      display: none;
    }

    &.back {
      .user,
      .number,
      .masterIcon,
      .chip {
        display: none;
      }

      .bant {
        display: block;
        position: absolute;
        background: grey;
        width: 100%;
        height: 50px;
        z-index: 9;
        left: 0;
        top: 40px;
      }

      .ccv {
        display: flex;
        position: absolute;
        background: $c-g1;
        width: 50%;
        height: 50px;
        z-index: 9;
        left: 40px;
        top: 50%;
        transform: rotateY(180deg);
        font-size: 1.2rem;
        padding: 5px 15px;
        align-items: center;
        justify-content: flex-end;
      }
    }
  }
}

.form {
  max-width: 600px;

  h3 {
    font-weight: 700;
    font-size: 0.8rem;
    margin-top: 15px;
  }
  form {
    align-items: flex-start;
    width: 100%;
  }

  .group {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    gap: 15px;
    width: 100%;
  }
}

@keyframes slideShow {
  from {
    transform: translateY(10px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
