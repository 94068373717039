@import "../../styles/variable";

.navbar {
    h2 {
        font-size: 1.2rem;
        font-weight: 500;
        margin-bottom: 15px;
    }

    background: $c-g1;
    height: 100vh;
    width: 100%;
    border-radius: 30px 0 0 30px;
    padding: 40px 50px;
    overflow-y: scroll;
    @include scrollStyle;
}