@import "../styles/variable";

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px transparent inset !important;
  border-radius: 16px;
  position: relative;
  z-index: 0;
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 25px;
  position: relative;
}

input,
textarea {
  width: 100%;
  border: none;
  line-height: 50px;
  padding: 0 10px 0 45px;
  caret-color: $c1;
  color: #666d70;
  background: transparent;
  position: relative;
  z-index: 1;
  transition: all 0.5s ease;

  &:focus {
    outline: none;
  }
}

.formGroup {
  display: flex;
  flex-direction: column;
  gap: 7px;
  width: 100%;
  position: relative;
  background: #f0f4f8;
  border-radius: 14px;
  transition: all 0.3s ease;
  border: 1px solid transparent;

  label {
    font-size: 14px;
    font-weight: 600;
    position: absolute;
    top: 18px;
    left: 45px;
    transition: all 0.5s ease;
    user-select: none;
    visibility: visible;
    z-index: 0;
  }

  svg {
    position: absolute;
    height: 50px;
    left: 10px;
  }

  &:has(input:focus) {
    border: 1px solid $c1;
    label {
      font-size: 13px;
      top: -15px;
      left: 5px;
    }
  }
  &:has(.filled) {
    label {
      font-size: 13px;
      top: -15px;
      left: 5px;
    }
  }
  &:has(input.input-error) {
    border: none;
  }
}
.login {
  .error {
    position: absolute;
    bottom: -63px;
    color: red;
    font-size: 12px;
  }
  .input-error {
    border: 1px solid red;
    border-radius: 10px;
  }
  .loginFailed {
    text-align: center;
    background-color: crimson;
    color: white;
    border-radius: 5px;
    padding: 10px 15px;
  }
  .emailSuccess {
    text-align: center;
    background-color: rgb(53, 152, 3);
    color: white;
    border-radius: 5px;
    padding: 10px 15px;
  }
}

button {
  text-transform: uppercase;

  .turn {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 25px;
    width: 25px;
    transform-origin: 50% 50%;
    text-align: center;

    &:hover {
      animation: turn 2s linear infinite;
    }
  }
}

input[type="radio"] {
  appearance: none;
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  border: 1px solid $c1;
  border-radius: 50%;
  margin-right: 10px;
  background-color: transparent;
  position: relative;
  padding: 0;
}

input[type="radio"]:checked::before {
  content: "";
  display: block;
  width: 12px;
  height: 12px;
  background-color: $c1;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: appear 0.6s;
}

@keyframes appear {
  0% {
    transform: translate(-50%, -50%) scale(0);
    background-color: #fff;
  }
  45% {
    transform: translate(-50%, -50%) scale(1.6);
    background-color: $c2;
  }
  50% {
    transform: translate(-50%, -50%) scale(1.7);
    background-color: $c2;
  }
  55% {
    transform: translate(-50%, -50%) scale(1.6);
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
    background-color: $c1;
  }
}

@keyframes turn {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
