@import "../../styles/variable";

.centerPage {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(90deg, #fff, #f6f7f8);

    .card {
        width: 500px;
        background: #fff;
        padding: 0;
        overflow: hidden;
        gap: 0;
        box-shadow: 0 5px 50px -5px rgba(100,100,100,.4);

        .cardHeader {
            display: flex;
            flex-direction: column;
            gap: 10px;
            align-items: center;
            justify-content: center;
            height: 200px;

            &.success {
                background: #A5DC86;
            }

            &.feild {
                background: #F74444;
            }

            h1 {
                font-size: 1.3rem;
                font-weight: 600;
                color: #fff;
            }
        }
        .cardBody {
            padding: 50px 20px;

            p {
                font-size: 1.1rem;
                font-weight: 500;
                margin-bottom: 35px;
            }

            .btnSucccess {
                background: #A5DC86;
                padding: 15px 40px;
                text-decoration: none;
                box-shadow: 0 5px 20px -5px rgba(108,193,60,.7);
                transition: all .4s;
                text-transform: uppercase;
                font-weight: 600;
                letter-spacing: 0.5px;
            }
            .btnSucccess:hover {
                box-shadow: 0 5px 10px -10px rgba(108,193,60,1);
                background: #9dd47e;
            }
            .btnInfo {
                background: #4d4d4d;
                padding: 15px 40px;
                text-decoration: none;
                box-shadow: 0 5px 20px -5px rgba(100,100,100,.7);
                transition: all .4s;
                text-transform: uppercase;
                font-weight: 600;
                letter-spacing: 0.5px;
            }
            .btnInfo:hover {
                box-shadow: 0 5px 10px -10px rgba(100,100,100,1);
                background: #303030;
            }
        }
    }
}


//SUCCESS UNSUCCESSFUL ANIMATE
.ui-success,.ui-error {
	width: 100px; 
    height: 100px;
}

.ui-success{
	&-circle {
		stroke-dasharray: 260.75219024795285px, 260.75219024795285px;
        stroke-dashoffset: 260.75219024795285px;
        transform-origin: center center;
		stroke-linecap: round;
		animation: ani-success-circle 1s ease-in both;
	}
	&-path {
		stroke-dasharray: 60px 64px;
    stroke-dashoffset: 62px;
		stroke-linecap: round;
		animation: ani-success-path .4s 1s ease-in both;
	}
}

@keyframes ani-success-circle {
	to{stroke-dashoffset: 782.2565707438586px;}
}

@keyframes ani-success-path {
	0% {stroke-dashoffset: 62px;}
	65% {stroke-dashoffset: -5px;}
	84%{stroke-dashoffset: 4px;}
	100%{stroke-dashoffset: -2px;}
}

.ui-error{
	&-circle{
		stroke-dasharray:260.75219024795285px, 260.75219024795285px;
		stroke-dashoffset: 260.75219024795285px;
		animation: ani-error-circle 1.2s linear;
	}
	&-line1{
		stroke-dasharray: 54px 55px;
		stroke-dashoffset: 55px;
		stroke-linecap: round;
		animation: ani-error-line .15s 1.2s linear both;
	}
	&-line2{
		stroke-dasharray: 54px 55px;
		stroke-dashoffset: 55px;
		stroke-linecap: round;
		animation: ani-error-line .2s .9s linear both;
	}
}

@keyframes ani-error-line{
	to { stroke-dashoffset: 0; }
}

 @keyframes ani-error-circle {
		0% {
				stroke-dasharray: 0, 260.75219024795285px;
				stroke-dashoffset: 0;
		}
		35% {
				stroke-dasharray: 120px, 120px;
				stroke-dashoffset: -120px;
		}
		70% {
				stroke-dasharray: 0, 260.75219024795285px;
				stroke-dashoffset: -260.75219024795285px;
		}
		100% {
				stroke-dasharray: 260.75219024795285px, 0;
				stroke-dashoffset: -260.75219024795285px;
		}
}