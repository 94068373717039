@import "./styles/variable";
@import "./styles/font";
@import "./styles/typo";
@import "./styles/breakpoint";

body {
    background: $c-bg;
    color: $c-font;
    overflow: hidden;
    font-family: $font-family;
    font-size: 15px;
    font-weight: 400;
    scroll-behavior: smooth;

    #root {
        display: flex;
        gap: 25px;
    }
}

.mainContent {
    width: 100%;

    .pages {
        display: grid;
        grid-template-columns: auto;
        &>div {
            overflow-y: auto;
            height: 100vh;
            padding-right: 25px;
            
            @include scrollStyle;
        }

        &.activeNavBar {
            grid-template-columns: auto 400px;


            @include mobile {
                grid-template-columns: 1fr;
            }
        }

        .content {
            max-width: 1200px;
            margin: 0 auto;
        }
    }
}

.row {
  width: 100%;
  max-width: 1200px; // İsteğe bağlı, bir konteyner genişliği belirlenmiş.
  margin: 0 auto;    // İsteğe bağlı, konteyneri ortalamak için.
  display: flex;

  &.g30 {
    gap: 30px;
  }

  &.mt-80 {
    margin-top: 80px;
  }
  
  @include generate-columns;
}

table {
    td {
        vertical-align: middle;
    }
}

.alertColor {
    color: rgb(221, 16, 16);
}

a {
    color: $c1;
    text-decoration: none;
}