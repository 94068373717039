$c-bg: #fff;
$c-font: #7d8388;
$c1: #086c73;
$c2: #031b1d;

$c-g1: #f6f7fa;
$c-border: #e6e6e6;


$mobile-width: 768px;
$tablet-width: 1200px;

$font-family: 'Gilroy';

@mixin scrollStyle {
    /* width */
    &::-webkit-scrollbar {
        width: 8px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: $c-bg; 
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: $c-font; 
        transition: all .4s ease;
        border-radius: 5px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: $c1; 
    }
}

$column-count: 12; // Sütun sayısını isteğe bağlı olarak belirleyebilirsiniz

@mixin generate-columns {
  @for $i from 1 through $column-count {
    .col-#{$i} {
      width: (100% / $column-count) * $i;
    }
  }
}