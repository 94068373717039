.list {
  button.detail {
    height: 40px;
    aspect-ratio: 1;
  }
  a.detail.mr5 {
    margin-right: 10px;
  }
  .textRed {
    color: crimson;
    position: relative;
    top: 3px;
    font-size: 12px;
  }
}

.p-datatable-table {
  tr {
    border-bottom: 1px solid #dfdfdf;
  }
}

.refundText {
  text-align: center;
  color: #fff;
  background-color: olivedrab;
  padding: 15px 10px;
  border-radius: 10px;
  font-weight: bold;
  margin-top: 15px;
}

.orderDetail {
  position: relative;

  .btnSm {
    position: absolute;
    right: 0;
    top: -38px;
  }

  .orderDetailInfo {
    p {
      display: grid;
      grid-template-columns: 2fr 3fr;
      span:first-child {
        font-weight: 600;
      }
    }

    small {
      display: block;
      margin-top: 20px;
      font-size: 14px;
      line-height: 1.4;
    }
  }

  .reload {
    display: none;
    width: 100%;
    min-height: 310px;
    border: 1px solid #ccc;
    border-radius: 10px;
  }

  &.is-animating {
    .orderDetailInfo {
      display: none;
    }
    .reload {
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        width: 30px;
        animation: turn 1.5s linear infinite;
      }
    }
  }
}
