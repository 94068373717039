@import "../../styles/variable";

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;

    .user {
        padding: 10px 20px;
        border: 1px solid $c-border;
        border-radius: 10px;
        display: flex;
        align-items: center;
        gap: 10px;

        svg {
            filter: grayscale(1) opacity(.8);
            width: 20px;
        }
    }
}