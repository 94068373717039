.swiper {
    height: 100%;
    position: relative;

    .swiper-wrapper {
        .swiper-slide {
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }

    .swiper-pagination {
        display: flex;
        flex-direction: column;
        gap: 5px;
        position: absolute;
        right: 8px;
        left: auto;
        top: 50%;
        bottom: auto;
        transform: translateY(-50%);
        width: 20px;

        .swiper-pagination-bullet {
            width: 12px;
            height: 12px;
            display: block;
            background: #fff;
            border-radius: 12px;
            transition: all 0.5s;

            &.swiper-pagination-bullet-active {
                height: 24px;
            }
        }
    }
}