@font-face{
    font-family:Gilroy;
    font-display:block;
    src:url(../assets/fonts/Gilroy-Light.woff2) format('woff2'),
        url(../assets/fonts/Gilroy-Light.woff) format('woff'),
        url(../assets/fonts/Gilroy-Light.ttf) format('truetype');
    font-weight:300;
    font-style:normal
}

@font-face{
    font-family:Gilroy;
    font-display:block;
    src:url(../assets/fonts/Gilroy-LightItalic.woff2) format('woff2'),
        url(../assets/fonts/Gilroy-LightItalic.woff) format('woff'),
        url(../assets/fonts/Gilroy-LightItalic.ttf) format('truetype');
    font-weight:300;
    font-style:italic
}

@font-face{
    font-family:'Gilroy';
    font-display:block;
    src:url(../assets/fonts/Gilroy-Regular.woff2) format('woff2'),
        url(../assets/fonts/Gilroy-Regular.woff) format('woff'),
        url(../assets/fonts/Gilroy-Regular.ttf) format('truetype');
    font-weight:400;
    font-style:normal
}

@font-face{
    font-family:'Gilroy';
    font-display:block;
    src:url(../assets/fonts/Gilroy-RegularItalic.woff2) format('woff2'),url(../assets/fonts/Gilroy-RegularItalic.woff) format('woff'),url(../assets/fonts/Gilroy-RegularItalic.ttf) format('truetype');
    font-weight:400;
    font-style:italic}
@font-face{
    font-family:'Gilroy';
    font-display:block;
    src:url(../assets/fonts/Gilroy-Medium.woff2) format('woff2'),url(../assets/fonts/Gilroy-Medium.woff) format('woff'),url(../assets/fonts/Gilroy-Medium.ttf) format('truetype');
    font-weight:500;
    font-style:normal}
@font-face{
    font-family:'Gilroy';
    font-display:block;
    src:url(../assets/fonts/Gilroy-MediumItalic.woff2) format('woff2'),url(../assets/fonts/Gilroy-MediumItalic.woff) format('woff'),url(../assets/fonts/Gilroy-MediumItalic.ttf) format('truetype');
    font-weight:500;
    font-style:italic}
@font-face{
    font-family:'Gilroy';
    font-display:block;
    src:url(../assets/fonts/Gilroy-Semibold.woff2) format('woff2'),url(../assets/fonts/Gilroy-Semibold.woff) format('woff'),url(../assets/fonts/Gilroy-Semibold.ttf) format('truetype');
    font-weight:600;
    font-style:normal}
@font-face{
    font-family:'Gilroy';
    font-display:block;
    src:url(../assets/fonts/Gilroy-SemiboldItalic.woff2) format('woff2'),url(../assets/fonts/Gilroy-SemiboldItalic.woff) format('woff'),url(../assets/fonts/Gilroy-SemiboldItalic.ttf) format('truetype');
    font-weight:600;
    font-style:italic}
@font-face{
    font-family:'Gilroy';
    font-display:block;
    src:url(../assets/fonts/Gilroy-Bold.woff2) format('woff2'),url(../assets/fonts/Gilroy-Bold.woff) format('woff'),url(../assets/fonts/Gilroy-Bold.ttf) format('truetype');
    font-weight:700;
    font-style:normal}
@font-face{
    font-family:'Gilroy';
    font-display:block;
    src:url(../assets/fonts/Gilroy-BoldItalic.woff2) format('woff2'),url(../assets/fonts/Gilroy-BoldItalic.woff) format('woff'),url(../assets/fonts/Gilroy-BoldItalic.ttf) format('truetype');
    font-weight:700;
    font-style:italic}