@import "../../styles/variable";

.printBtn {
  position: fixed;
  top: 20px;
  right: 2cap;
  background: $c1;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  box-shadow: 0 5px 15px -5px rgba($c1, 0.5);
}

.printWrapper {
  width: 90%;
  height: calc(100vh - 150px);
  overflow-y: scroll;
  box-shadow: 0 5px 50px -5px rgba(100, 100, 100, 0.4);
  margin: 60px 20px;

  .printArea {
    padding: 50px 35px;
    h1 {
      text-align: center;
      font-size: 1.4rem;
      font-weight: 600;
    }
    .date {
      text-align: right;
      font-weight: 500;
    }
    ul {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
    .mt-150 {
      margin-top: 150px;
    }
  }
}

@media print {
  .printArea {
    font-size: 10pt;
    margin: 30px;
    line-height: 1.9;

    .date {
      text-align: right;
      font-weight: 500;
    }

    h1 {
      font-size: 18pt;
      margin-bottom: 20px;
      text-align: center;
    }

    .mt-150 {
      margin-top: 90px;
    }
  }
}
